import {db} from "../firebase"

class MarketplaceService {
    async getAll() {
        const snapshot = await db.collection("marketplaceItems").get()
        const documents = [];
        snapshot.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    async get(id){
        const marketplace = await db.collection("marketplaceItems").doc(id).get()
        return marketplace.data()
    }

    create(item) {
        db.collection("marketplaceItems").add(item)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("marketplaceItems").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("marketplaceItems").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new MarketplaceService();