<template>
  <div class="marketplace-item" v-show="!isLoading">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Item for Sale</h1>
        </div>
      </div>
    </section>
    <section class="event-content">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div id="slider">
              <transition-group tag="div" :name="transitionName" class="slides-group">
                <div v-if="show" :key="current" class="slide">
                  <img :src="item.images[current]"/>
                </div>
              </transition-group>
              <div class="btn btn-prev" aria-label="Previous slide" @click="slide(-1)">
                &#10094;
              </div>
              <div class="btn btn-next" aria-label="Next slide" @click="slide(1)">
                &#10095;
              </div>
            </div>
          </div>
          <div class="column">
            <h2 class="subtitle is-3">{{ item.name }}</h2>
            <p class="is-size-6 description">{{ item.description }}</p>
            <p class="is-size-6" v-if="item.contactPerson">
              <strong>Contact:</strong>
              {{ item.contactPerson }}
            </p>
            <p class="is-size-6" v-if="item.location">
              <strong>Location:</strong>
              {{ item.location }}
            </p>
            <p class="is-size-6" v-if="item.phoneNumber">
              <strong>Phone Number:</strong>
              {{ item.phoneNumber }}
            </p>
            <p class="is-size-6" v-if="item.quantity">
              <strong>Quantity:</strong>
              {{ item.quantity }}
            </p>
            <p class="is-size-6" v-if="item.created">
              <strong>Date Listed:</strong>
              {{ item.created.toDate().toDateString() }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MarketplaceService from "../services/marketplaceService"

export default {
  name: "MarketplaceItem",
  data() {
    return {
      isLoading: false,
      item: {},
      current: 0,
      direction: 1,
      transitionName: "fade",
      show: false,
      slides: [
        {className: "blue"},
        {className: "red"},
        {className: "yellow"}
      ]
    };
  },
  async mounted() {
    this.show = true;
    this.item = await MarketplaceService.get(this.$route.params.id)
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      dir === 1
          ? (this.transitionName = "slide-next")
          : (this.transitionName = "slide-prev");
      var len = this.item.images.length;
      this.current = (this.current + dir % len + len) % len;
    }
  },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");

/* SLIDER STYLES */
body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
}

#slider {
  width: 100%;
  height: 100vh;
  position: relative;
}

.slide {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.btn {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  height: 70px;
  position: absolute;
  top: calc(50% - 35px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
  color: white;
}
.btn-prev{
  color: white;

}

.btn:hover {
  transform: scale(1.15);
}
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
      to right,
      #93291e,
      #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
      to right,
      #93291e,
      #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 40px;
}
.hero.is-primary {
  background-color: transparent;
}
.ensemble-container {
  margin: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .description {
    margin: 10px;
}}

</style>